import React from 'react'
import { ModalStyled } from '../../components/modals/styled'
import { Alert, Input, Typography } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addUserToDB } from '../../redux/users/actionCreator'
import { EyeInvisibleOutlined, EyeTwoTone, } from "@ant-design/icons";

const AddUserModal = ({ modalToOpen, setModalToOpen }) => {
    const [addUserData, setAddUserData] = useState({ name: "", email: "", password: "", instance_name: "" })
    const [error, setError] = useState(false)
    const dispatch = useDispatch()
    const onCancel = () => {
        setModalToOpen("")
        setAddUserData({ name: "", email: "", password: "", instance_name: "" })
        setError(false)
    }
    const onOk = () => {
        const { name, email, password, instance_name } = addUserData
        if (!name.trim() || !email.trim() || !password.trim() || !instance_name.trim()) {
            setError(true)
        }
        else {
            dispatch(addUserToDB(addUserData));
            setModalToOpen("")
            setAddUserData({ name: "", email: "", password: "", instance_name: "" })
        }
    }
    const onChange = (name, value) => {
        setAddUserData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    return (
        <ModalStyled
            title={"Add New User"}
            visible={modalToOpen === "addUserModal"}
            onOk={onOk}
            onCancel={onCancel}
            bodyStyle={{ maxHeight: "55vh", overflow: "auto" }}
        >

            <Typography>Name</Typography>
            <Input
                style={{ marginTop: "10px" }}
                type="text"
                className="form-control"
                placeholder="name"
                value={addUserData.name}
                id="name"
                onChange={(e) => {
                    onChange("name", e.target.value)
                    // if (name.trim()) { setError(false) }
                }}
            ></Input>
            <Typography
                style={{ marginTop: "10px" }}>
                Email
            </Typography>
            <Input
                type="text"
                className="form-control"
                placeholder="email@gmail.com"
                value={addUserData.email}
                id="email"
                onChange={(e) => {
                    onChange("email", e.target.value)
                }}
            ></Input>
            <Typography
                style={{ marginTop: "10px" }}>
                Password
            </Typography>
            <Input.Password
                className="form-control"
                placeholder="password"
                value={addUserData.password}
                id="password"
                onChange={(e) => {
                    onChange("password", e.target.value)
                }}
                iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
            />
            <Typography
                style={{ marginTop: "10px" }}>
                Instance Name
            </Typography>
            <Input
                type="text"
                className="form-control"
                placeholder="instane name"
                value={addUserData.instance_name}
                id="instance_name"
                onChange={(e) => {
                    onChange("instance_name", e.target.value)
                }}
            ></Input>
            {error &&
                <Alert style={{ marginTop: "10px" }} type="error" message={'Please fill all the required fields !'} banner />}
        </ModalStyled>
    )
}

export default AddUserModal