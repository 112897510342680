import { Button, Form, Input, Spin, Divider, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import { Checkbox } from "../../../../components/checkbox/checkbox";
import Heading from "../../../../components/heading/heading";
import openNotificationWithIcon from "../../../../components/notification/notification";
import { setIsLoggingIn, login } from "../../../../redux/authentication/actionCreator";
import { getUserDetails, setUserDetails } from "../../../../redux/user-details/actionCreator";
// eslint-disable-next-line import/no-extraneous-dependencies
// import { FacebookOutlined, TwitterOutlined } from "@ant-design/icons";
import { AuthWrapper } from "./style";
import { Typography } from 'antd';
import ziwoRocket from "./ziwoSignIn.png"
import "../imageAnimation.css"
const SignIn = () => {
  const history = useHistory();
  const { userList, } = useSelector(state => {
    return {
      userList: state.userDetails.userList,
    };
  });

  const dispatch = useDispatch();

  const { isLoggingIn } = useSelector(state => {
    return {
      isLoggingIn: state.auth.isLoggingIn,
    };
  });

  const [form] = Form.useForm();

  const [submittedAndEmpty, setSubmittedAndEmpty] = useState(false);
  // let loadingDots = Array.from(Array(loadingDotsNumber), (x, y) => '.');
  // loadingDots = loadingDots.join("");

  // useEffect(() => {
  //   if (isLoggingIn && loadingDotsNumber) {
  //     setTimeout(() => {
  //       let newLoadingDotsNumber = loadingDotsNumber === 3 ? 1 : loadingDotsNumber + 1;
  //       setLoadingDotsNumber(newLoadingDotsNumber);
  //     }, 500);
  //   }
  // }, [isLoggingIn, loadingDotsNumber]);// eslint-disable-line

  // const [state, setState] = useState({
  //   checked: null,
  // });

  const handleSubmit = (values) => {
    let email = values.username;
    let { password } = values;
    if (email.trim().length === 0 || password.trim().length === 0) {
      setSubmittedAndEmpty(true)
      return
    }
    dispatch(setIsLoggingIn(true));
    let config = {
      method: "post",
      url: process.env.REACT_APP_CARDS_URL + "user/login",
      data: {
        password: password.trim(),
        email: email.trim(),
      }
    };
    axios(config)
      .then((response) => {
        if (!response.error) {
          const { token, username, userId } = response.data;
          localStorage.setItem('token', token);
          dispatch(login(token, username, email, userId));
          dispatch(getUserDetails(token, history));
          // dispatch(getUserDetails(token));

          // let config = {
          //   method: "get",
          //   url: "http://20.232.174.69/rbac/list_menu_elts",
          //   headers: { Authorization: "Bearer " + token },
          // };

          // axios(config)
          //   .then((response) => {
          //     dispatch(setUserDetails({

          //       "Stories": [
          //         "read"
          //       ]
          //     }));

          //     let firstKeyInUserDetails = Object.keys({

          //       "Stories": [
          //         "read"
          //       ]
          //     })[0]

          // if (firstKeyInUserDetails == "Adaptive Cards") {
          //   history.push("/dashboard/cards");
          // }
          // else if (firstKeyInUserDetails == "Chatbots") {
          //   history.push("/dashboard/chatbot");


          // }
          // else if (firstKeyInUserDetails == "Deployments") {
          //   history.push("/dashboard/deployments");


          // }
          // else if (firstKeyInUserDetails == "Flows") {
          //   history.push("/dashboard");


          // }
          // else if (firstKeyInUserDetails == "Knowledge AI") {
          //   history.push("/dashboard/knowledge-ai");


          // }
          // else if (firstKeyInUserDetails == "Stories") {
          //   history.push("/dashboard/stories");


          // }
          //   openNotificationWithIcon('success', 'User Details', 'User Details Loaded successfully');
          // })
          // .catch((error) => {
          //   console.log('User Details Error', error);
          // });






        }
        else {
          openNotificationWithIcon('error', 'Failed', response.error);
        }
        dispatch(setIsLoggingIn(false));
      })
      .catch((error) => {
        console.log({ error });
        if (error?.response?.data?.error) {
          openNotificationWithIcon('error', 'Failed', error.response.data.error);
          dispatch(setIsLoggingIn(false));
        }
        else {
          openNotificationWithIcon('error', 'Failed', error.message);
          console.log("Error getting data: " + error);
          dispatch(setIsLoggingIn(false));
        }
      });

  };

  // const onChange = (checked) => {
  //   setState({ ...state, checked });
  // };

  return (
    <AuthWrapper style={{ display: "flex" }}>

      <div className="auth-contents"
      >
        <Form
          name="login"
          form={form}
          onFinish={handleSubmit}
          // style={{borderRadius:"10px",border:"1px solid #dbd5d569",padding:"20px",boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}

          layout="vertical"
        >
          <Heading as="h3" ><img src={ziwoRocket} width={"30%"} /></Heading>
          <Heading as="h6" className={"heading"}>Welcome to Ziwo Workflow! 👋🏻</Heading>
          <Typography.Paragraph style={{ color: "#272b4173" }}>Please sign-in to your account and start the<br /> adventure </Typography.Paragraph>
          <Form.Item
            name="username"
            // rules={[
            //   {
            //     message: "Please input your username or Email!",
            //     required: true,
            //   },
            // ]}
            initialValue=""
            label="Email Address"
          >
            <Input placeholder="Email" style={submittedAndEmpty ? { border: "1px solid red" } : {}} />
          </Form.Item>
          <Form.Item name="password" initialValue="" label="Password">
            <Input.Password placeholder="Password" autoComplete="off" style={submittedAndEmpty ? { border: "1px solid red" } : {}} />
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="btn-signin"
              htmlType="submit"
              type="primary"
              size="middle"
              style={{ width: "100%", borderRadius: "10px", fontFamily: "sans-serif", fontWeight: "700" }}
              disabled={isLoggingIn}
            >
              {/* {isLoggingIn ? "Loading" + loadingDots : "Sign In"} */}
              {isLoggingIn ? <span><span>SIGN IN</span> <Spin style={{ paddingLeft: "10px" }} size="small" /></span> : "SIGN IN"}

            </Button>
          </Form.Item>
          {/* <Typography.Paragraph style={{color:"#272b4173",textAlign:"center"}}>New on our platform?  <a href="/signup">Create an account</a></Typography.Paragraph>
          <Divider style={{color:"rgba(50, 71, 92, 0.87)",fontWeight:"400",fontSize:"1rem", lineHeight:"1.5"}}>or</Divider>
          <div style={{display:"flex",justifyContent:"center",gap:"20px"}}><Tooltip title="Sign in with Google" className="icons"><a>
       <img className="icons" src={require("./google.svg")} alt="Sign in with Google" style={{ width: '25px', height: '25px' }} />        
      </a>
      </Tooltip>
      <Tooltip title="Sign in with Github">
      <a 
      >
        <img className="icons" src={require("./github.svg")} alt="Sign in with Github" style={{ width: '25px', height: '25px' }} />        
      </a>
      </Tooltip>
      </div> */}
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;