import { automateOAuth2 } from "./CredOAuth2";

export const OAuth2ServiceTypes = {
  Google: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: "https://oauth2.googleapis.com/token",
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        grant_type: "authorization_code",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "access_token::response",
        "refresh_token::response",
        "developerToken",
        "privateKey",
        "clientEmail",
        "expirey::response[expires_in]=>formatDateWithMilliseconds",
      ],
    }),
  ZohoCRM: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/zohoCRM/getRefreshToken`,
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      fieldsToAdd: ["clientID", "clientSecret", "refresh_token::response"],
    }),
  SalesForce: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `https://{{domainName}}.my.salesforce.com/services/oauth2/token?grant_type=authorization_code&code={{code::params}}&client_id={{clientID}}&client_secret={{clientSecret}}&redirect_uri=${process.env.REACT_APP_DOMAIN}/dashboard/credentials&code_verifier=A00F0D6C54EBCCBC3F5CA84771F43B2C79F503E8D560E7DC9003CE96CFE704D1`,
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "refresh_token::response",
        "domainName",
      ],
    }),
  Zoom: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/zoom/getRefreshToken`,
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "refresh_token::response",
        "redirectUri",
      ],
    }),
  HubSpot: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/hubspot/getRefresh`,
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "refresh_token::response",
        "redirectUri",
      ],
    }),
  Dropbox: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: "https://api.dropbox.com/oauth2/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ||{{appKey}}:{{appSecret}}=>Buffer`,
      },
      payload: {
        code: "{{code::params}}",
        grant_type: "authorization_code",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: ["appKey", "appSecret", "refresh_token::response"],
    }),
  Microsoft: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/microsoft/getToken`,
      payload: {
        clientId: "{{clientId}}",
        clientSecret: "{{clientSecret}}",
        code: "{{code::params}}",
        grant_type: "authorization_code",
        redirectUri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientId",
        "clientSecret",
        "access_token::response",
        "refresh_token::response",
        "domain",
        "expirey::response[expires_in]=>formatDateWithMilliseconds",
      ],
    }),
  Xero: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/xero/getAccessToken`,
      headers: {
        "Content-Type": "application/json",
      },
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "access_token::response",
        "refresh_token::response",
        "expirey::response[expires_in]=>formatDateWithMilliseconds",
      ],
    }),
  PipeDrive: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/pipedrive/getToken`,
      headers: {
        "Content-Type": "application/json",
      },
      payload: {
        clientID: "{{clientID}}",
        clientSecret: "{{clientSecret}}",
        code: "{{code::params}}",
        redirectUri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "access_token::response",
        "refresh_token::response",
        "expirey::response[expires_in]=>formatDateWithMilliseconds",
      ],
    }),
  QuickBooks: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/quickbooks/getRefreshToken`,
      headers: {
        "Content-Type": "application/json",
      },
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        realmId: "{{realmId::params}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data.token_info",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "access_token::response",
        "refresh_token::response",
        "realmId::params",
        "environment",
      ],
    }),
  Jira: (dispatch) =>
    automateOAuth2({
      dispatch,
      url: `${process.env.REACT_APP_UPLOAD_FILE_URL}/cloud/regular/jira/getCredsInfo`,
      headers: {
        "Content-Type": "application/json",
      },
      payload: {
        client_id: "{{clientID}}",
        client_secret: "{{clientSecret}}",
        code: "{{code::params}}",
        domain: "{{domain}}",
        redirect_uri: process.env.REACT_APP_DOMAIN + "/dashboard/credentials",
      },
      response_path: "data",
      fieldsToAdd: [
        "clientID",
        "clientSecret",
        "refresh_token::response",
        "domain",
        "cloud_id::response"
      ],
    }),
};
